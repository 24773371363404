import React from "react";
import {
  ApolloClient,
  createHttpLink,
  InMemoryCache,
  ApolloProvider,
} from "@apollo/client";

import { ConfigContextProvider } from "@simplur/gatsby-theme-full-site";
import AppConfig from "./appConfig";

import "./src/styles/style.scss";

export const wrapRootElement = ({ element }) => {
  const httpLink = createHttpLink({
    uri: `${process.env.GATSBY_WORDPRESS_URL}/graphql`,
  });

  const client = new ApolloClient({
    fetch,
    link: httpLink,
    cache: new InMemoryCache(),
    clientState: {},
  });

  return (
    <ApolloProvider client={client}>
      <ConfigContextProvider appConfig={AppConfig}>
        {element}
      </ConfigContextProvider>
    </ApolloProvider>
  );
};
